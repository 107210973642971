<template>
    <div class="modal fade" id="asignarCelebracion" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="asignarCelebracionLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Asignar celebración</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                <div class="modal-body">
                    <div v-if="loadingCelebraciones || celebraciones.length<=0">
                        <div v-if="loadingCelebraciones" class="text-muted text-center py-5">
                            <div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <h2>
                                Cargando...
                            </h2>
                        </div>
                        <div v-else class="py-3">
                            <div class="text-center mx-auto bg-light rounded-circle p-5" style="width: 200px; height: 200px;">
                                <img :src="SVGEmpty" class="img-fluid">
                            </div>
                            <div class="text-center">
                                <p>
                                    No se encontraron registros
                                </p>
                                <router-link :to="{name: 'admin.celebraciones.create'}" class="btn btn-primary">
                                    <font-awesome-icon :icon="faPlus" /> Agregar celebración
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <ul class="list-group" v-else>
                        <li class="list-group-item" v-for="(celebracion, index) in celebraciones" :key="index">
                            <div class="form-check form-switch">
                                <input type="checkbox" class="form-check-input" :id="'celebracion-' + index"
                                    :value="celebracion.codigo" v-model="celebracionesProducto" @click="check($event)">
                                <label class="custom-control-label" :for="'celebracion-' + index">
                                    {{ celebracion.nombre }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" :disabled="loading">Salir</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
export default {
    components: {
		FontAwesomeIcon,
    },

    data() {
        return {
            faPlus,
            celebracionesProducto: [],
            vecino: {},
            loadingCelebraciones: false,
        }
    },

    created() {
        this.loadData()
    },

    mounted() {
        this.loadCelebraciones()
        $(this.$el).modal('show')
        var vm = this
        $(this.$el).on('hidden.bs.modal', function () {
            vm.toIndex()
        })
    },

    methods: {
        loadCelebraciones() {
            this.$store.dispatch('getCatalogosCelebraciones')
        },

        loadData() {
        this.loadingCelebraciones = true
        this.$store.dispatch('showProducto1', this.$route.params.id)
            .then(response => {
                this.producto = response.data || {}
                if (this.producto && this.producto.celebraciones) {
                    this.celebracionesProducto = this.producto.celebraciones.map(x => x.CODIGO_CELEBRACION)
                }
            })
            .catch(error => {
                console.error('Error al cargar los datos:', error)
            }).then(() => {
                this.loadingCelebraciones = false
            })
        },

        toIndex() {
            this.$router.push({ name: 'admin.index' })
        },
        check(e) {
            if (e.target.checked) {
                this.addProductoCelebracion(this.producto.CODIGO_PRODUCTO, e.target.value)
            } else {
                this.removeProductoCelebracion(this.producto.CODIGO_PRODUCTO, e.target.value)
            }
        },
        addProductoCelebracion(producto, celebracion) {
            this.$store.dispatch('addProductoCelebracion', {
                producto: producto,
                celebracion: celebracion,
            })
                .then(() => {
                    this.$toast.info(`Celebración asignada`)
                }).catch(error => {
                    console.log(error.response)
                })
        },

        removeProductoCelebracion(producto, celebracion) {
            this.$store.dispatch('removeProductoCelebracion', {
                producto: producto,
                celebracion: celebracion,
            })
                .then(() => {
                    this.$toast.info(`Celebración desasignada`)
                }).catch(error => {
                    console.log(error.response)
                })
        },
    },

    computed: {
        loading() {
            return this.$store.getters.loading
        },

        celebraciones() {
            return this.$store.getters.listCelebraciones
        },

        productoParams() {
            return this.$route.params.id
        },
    },

    watch: {
        productoParams: function () {
            this.loadData()
        }
    }
}
</script>